export default {
  computed: {
    modalDifficultyAdd: ths => ({
      title: 'Добавить уровень сложности', 
      notification: 'Уровень сложности успешно добавлен', 
      id: 'difficulty-add', 
      request: 'api/difficulty',
      method: 'post', 
      emit: 'createSetting', 
      file: null, 
      file_request: null,
      variables: {
        name: '',
        points: ''
      },
      inputs: [{
        name: 'Уровень сложности *', 
        placeholder: 'Уровень сложности', 
        id: 'name', 
        grid: '1 / 4', 
        value: '', 
        drop: false
      }, {
        name: 'Бонусные баллые *', 
        placeholder: 'Бонусные баллые', 
        id: 'points', 
        grid: '4 / 7', 
        value: '', 
        drop: false
      }],
    }),

    modalDifficultyEdit: ths => ({
      title: 'Редактировать уровень сложности', 
      notification: 'Уровень сложности успешно отредактирован', 
      id: 'difficulty-edit', 
      request: 'api/difficulty', 
      method: 'put', 
      emit: 'editSetting', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element?._id,
        name: ths.modal.element?.name,
        points: ths.modal.element?.points,
      },
      inputs: [{
        name: 'Уровень сложности *', 
        placeholder: 'Уровень сложности', 
        id: 'name', 
        grid: '1 / 4', 
        value: ths.modal.element?.name, 
        drop: false,
      }, {
        name: 'Бонусные баллые *', 
        placeholder: 'Бонусные баллые', 
        id: 'points', 
        grid: '4 / 7', 
        value: ths.modal.element?.points, 
        drop: false,
      }],
    }),

    modalDifficultyRemove: ths => ({
      title: 'Удалить уровень сложности', 
      notification: 'Уровень сложности успешно удален', 
      id: 'difficulty-remove', 
      request: 'api/difficulty', 
      message: 'этот уровень сложности', 
      method: 'delete', 
      emit: 'removeSetting', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element._id,
      },
    }),
  },
};