import Vue from 'vue';
import _ from 'lodash';
import axios from "axios";

import DateFilter from '@/filters/date.filter';
import promiseWrapper from '../utils/promiseWrapper';
import getFromTo from '../utils/getFromTo';
import CONST from '../constants';

const store = {};

store.state = () => ({
  loading: true,
  startGameTime: 0,
  currentSoundId: null,
  currentSoundIndex: {},
  currentSoundWords: {},
  currentSoundAnswerWords: {},
  historySounds: {},
  historyGuessedSounds: [],
  settings: {},
  scoresValues: [],
  scoresUser: {
    total: 0,
  },
  currentDifficulty: 1,
  difficultyStatuses: {},
  speeds: {},
  sounds: {},
  library: [],
  token: '',
  limitSounds: 5,
  adCount: 0,

  completedSounds: {
    perSession: 0,
    sameLevel: 0,
    inRow: 0,
  },
});


store.getters = {
  loading: state => state.loading,
  historySounds: state => _.get(state.historySounds, state.currentDifficulty, {}),
  currentSoundIndex: state => _.get(state.currentSoundIndex, state.currentDifficulty, 0),
  notGuessedSounds: state => _.omitBy(state.sounds, sound => state.historyGuessedSounds.includes(sound._id)),
  currentSoundId: (state, getters) => state.currentSoundId,
  soundOfHistory: (state, getters) => _.get(getters.historySounds, getters.currentSoundIndex, {}),
  currentSoundWords: (state, getters) => _.get(state.currentSoundWords, state.currentSoundId, {}),
  currentSoundAnswerWords: (state, getters) => _.get(state.currentSoundAnswerWords, state.currentSoundId, {}),
  sound: (state, getters) => _.get(state.sounds, state.currentSoundId),

  currentSoundWordTipsCount(state, getters) {
    return _.size(_.get(getters.sound, 'fragment.tips'));
  },
  isUserCompleteMore5Sounds: state => true,
  scoresValues: state => state.scoresValues,
  settings: state => state.settings,
  library: state => state.library,
  speeds: state => state.speeds,
  sounds: state => _.pickBy(state.sounds, sound => {
    return sound.difficulty.name == state.currentDifficulty;
  }),
  difficultyStatuses: state => _.get(state.difficultyStatuses, state.currentDifficulty, {}),
  historyGuessedSounds(state, getters) {
    return _.omit(state.historyGuessedSounds, _.keys(getters.sounds));
  },
  sumGuessedSounds(state, getters, rootState, rootGetters) {
    const saveCount = rootGetters.user.guessed_sounds?.all?.count || 0;
    const guestCount = _.size(getters.historyGuessedSounds);
    return saveCount + guestCount;
  },
  canForwardDemo(getters) {
    const max = _.size(getters.sounds);
    const guessed = _.size(getters.historyGuessedSounds);
    const index = getters.currentSoundIndex[getters.currentDifficulty];
    if(guessed + index === (max -1)) return false;
    if(max - guessed < 2) return false;
    return true;
  },
  canBackwardDemo(getters) {
    const max = _.size(getters.sounds);
    const guessed = _.size(getters.historyGuessedSounds);
    const index = getters.currentSoundIndex[getters.currentDifficulty];
    if(index > 0 && guessed < (max -1)) return  true;
    return false;
  },
  soundsBylevel: state => _.groupBy(Object.values(state.sounds), 'difficulty.name'),
  tokenGame: state => state.token,
  currentDifficulty: state => state.currentDifficulty,
};


store.actions = {
  getGameLibrary: context => getFromTo(context, CONST.path.api.game.LIBRARY, 'setGameLibrary'),
  getGameSettings: context => getFromTo(context, CONST.path.api.game.SETTINGS, 'setGameSettings'),
  getGameScores: context => getFromTo(context, CONST.path.api.game.SCORES, 'setGameScores', 'data.data'),
  getGameSpeeds: context => getFromTo(context, CONST.path.api.game.SPEEDS, 'setGameSpeeds', 'data.data'),

  getGameSounds({ commit, rootGetters, getters, state }, customOptions = {}) {
    customOptions.demo = rootGetters.isDemoUser;
    return promiseWrapper(async () => {
      try {
        commit('setLoading', true);
        const headers = rootGetters.headers;
        // в парметрах передать уровень сложности и получить треки по нему
        const params = _.defaultsDeep(getters.settings, customOptions);
        params.skip = Math.floor((_.size(getters.historyGuessedSounds) + _.size(getters.historySounds) + 1) / state.limitSounds) * state.limitSounds;
        params.difficulty = getters.currentDifficulty;
        params.limit |= state.limitSounds;
        const response = await axios.get(CONST.path.api.game.SOUNDS, { params, headers });
        if (!response.data.sounds.length) commit('loadAllSoundsByDifficulty', params.difficulty);
        const sounds = response.data.sounds.reduce((acc, sound) => {
          const statuses = { play: false };
          acc[sound._id] = Object.assign(sound, { statuses });
          return acc;
        }, {});
        commit('setSounds', sounds);
        params.withToken && commit('setToken', response.data.token);
        if (!_.size(getters.historySounds) && _.size(sounds)) {
          commit('addToHistory', _.sample(sounds)._id);
          commit('setStartGameTime');
        };
      } catch(err) {
        console.error(err);
      } finally {
        commit('setLoading', false);
      };
    });
  },

  checkWordBySoundId({ rootGetters, getters }, { word, position }) {
    return promiseWrapper(async () => {
      try {
        Vue.set(getters.sound.statuses, 'checkWord', true);
        const headers = rootGetters.headers;
        const params = { word, position, soundId: getters.sound._id };
        const response = await axios.get(CONST.path.api.game.CHECK_WORD, { params, headers });
        return response.data.valid;
      } catch(err) {
        console.error(err);
      } finally {
        Vue.set(getters.sound.statuses, 'checkWord', false);
      };
    });
  },

  checkAllWordsBySoundId({ rootGetters, getters }, { words }) {
    return promiseWrapper(async () => {
      try {
        Vue.set(getters.sound.statuses, 'checkWords', true);
        const headers = rootGetters.headers;
        if (Array.isArray(words)) words = JSON.stringify(words);
        const params = { words, soundId: getters.sound._id };
        const response = await axios.get(CONST.path.api.game.CHECK_WORDS, { params, headers });
        return response.data.valid;
      } catch(err) {
        console.error(err);
      } finally {
        Vue.set(getters.sound.statuses, 'checkWords', false);
      };
    });
  },

  useWordTipBySoundId({ commit, rootGetters, getters }, { position }) {
    return promiseWrapper(async () => {
      try {
        Vue.set(getters.sound.statuses, 'useWordTip', true);
        const headers = rootGetters.headers;
        const params = { position, soundId: getters.sound._id };
        const response = await axios.get(CONST.path.api.game.USE_WORD_TIP, { params, headers });
        commit('addWordTipsToSound', { sound: getters.sound, words: response.data.words });
        return response.data;
      } catch(err) {
        console.error(err);
      } finally {
        Vue.set(getters.sound.statuses, 'useWordTip', false);
      };
    });
  },

  useNameTipBySoundId({ commit, rootGetters, getters }) {
    return promiseWrapper(async () => {
      try {
        Vue.set(getters.sound.statuses, 'useNameTip', true);
        const headers = rootGetters.headers;
        const params = { soundId: getters.sound._id };
        const response = await axios.get(CONST.path.api.game.USE_SOUND_TIP, { params, headers });
        commit('addNameTipToSound', { sound: getters.sound, name: response.data.name });
        return response.data;
      } catch(err) {
        console.error(err);
      } finally {
        Vue.set(getters.sound.statuses, 'useNameTip', false);
      };
    });
  },

  setShowSoundName({ commit, getters }, value) {
    const index = getters.currentSoundIndex;
    commit('setShowSoundNameByIndex', { index, value });
  },

  getGameDemoSounds({ commit, rootGetters, getters, state }) {
    return promiseWrapper(async () => {
      try {
        commit('setLoading', true);
        const options = { ...rootGetters.axiosOptions };
        const response = await axios.get(CONST.path.api.game.SOUNDS_DEMO, options);
        const sounds = response.data.data.reduce((acc, sound) => ({ ...acc, [sound._id]: { ...sound, play: false }}), {});
        commit('setSounds', sounds);
        if (!_.size(getters.historySounds) && _.size(sounds)) {
          commit('addToHistory', _.sample(sounds)._id);
          commit('setStartGameTime');
        };
        //commit('setToken', response.data.token);
        commit('setLoading', false);
      } catch(err) {
        console.error(err);
      }
    });
  },

  getAuthorsBySoundId({ commit, getters, rootGetters }) {
    return promiseWrapper(async () => {
      commit('setLoading', true);
      const url = CONST.path.api.game.GET_AUTHORS;
      const soundId = getters.sound._id;
      const tokenGame = getters.tokenGame;
      const params = { soundId, tokenGame };
      const headers = rootGetters.headers;
      const response = await axios.get(url, { params, headers });
      commit('setLoading', false);
      return response.data.authors;
    });
  },

  finishGame({ commit, getters, rootGetters }, { authorId, duration, words }) {
    return promiseWrapper(async () => {
      commit('setLoading', true);
      const payload = { authorId, duration, words };
      payload.tokenGame = getters.tokenGame;
      payload.soundId = getters.sound._id;
      const url = CONST.path.api.game.SOUND_FINISH;
      const response = await axios.post(url, payload, rootGetters.axiosOptions);
      commit('setToken', response.data.token);
      // guessedCount
      commit('setLoading', false);
      return response.data;
    });
  },

  completeDemo({ commit, getters, rootGetters }) {
    return promiseWrapper(async () => {
      commit('setLoading', true);
      const url = CONST.path.api.game.DEMO_COMPLETE;
      const response = await axios.post(url, payload, rootGetters.axiosOptions);

      commit('setLoading', false);
    });
  },

  addGuessedSound({ getters, rootGetters }) {
    return promiseWrapper(() => {
      return axios.put(
        CONST.path.api.game.SOUND,
        { _id: getters.sound._id },
        rootGetters.axiosOptions,
      );
    });
  },
  getSoundName: (getters) => getters.sound.sound_name,
  nextSound: function ({state, getters, commit, dispatch}, clear = false) {
    return promiseWrapper(async () => {
      const index = getters.currentSoundIndex;
      const isExistNextSound = !!getters.historySounds[index + 1];
      const levelNotGuessed = _.pickBy(getters.notGuessedSounds, obj => obj.difficulty.name == parseInt(state.currentDifficulty));
      if(_.size(levelNotGuessed) == 0){
        await dispatch('getGameSounds');
      }
      if (!isExistNextSound) {
        //await dispatch('onSoundSkip');
        let levelNotGuessed = _.pickBy(getters.notGuessedSounds, obj => obj.difficulty.name == parseInt(state.currentDifficulty));
        const soundsIds = _.keys(levelNotGuessed);
        const historyIds = _.map(getters.historySounds, 'id');
        const newSoundId = _.sample(_.omitBy(soundsIds, id => historyIds.includes(id)));
        commit('addToHistory', newSoundId);
      }

      const needGetNewSounds = _.size(getters.historyGuessedSounds) + _.size(getters.historySounds) + 1 >= _.size(getters.sounds);
      if (needGetNewSounds) {
        await dispatch('getGameSounds');
      }
      commit('incrementSoundIndex');
      commit('setStartGameTime');
      setTimeout(dispatch, 0, 'onSoundLaunch');
      if (isExistNextSound) return;
      commit('incrementAdCount');
      if (state.adCount % state.settings.length_between_ads) return;
      commit('clearAdCount');
      await dispatch('showADS');
    });
  },
  nextSoundDemo: function ({state, getters, commit, dispatch}, clear = false) {
    return promiseWrapper(async () => {
      const index = getters.currentSoundIndex;
      const isExistNextSound = !!getters.historySounds[index + 1];
      commit('incrementSoundIndex');
      commit('setStartGameTime');;

      if (!isExistNextSound) {
        //await dispatch('onSoundSkip');
        let levelNotGuessed = _.pickBy(getters.notGuessedSounds);
        const soundsIds = _.keys(levelNotGuessed);
        const historyIds = _.map(getters.historySounds, 'id');
        const newSoundId = _.sample(_.omitBy(soundsIds, id => historyIds.includes(id)));
        commit('addToHistory', newSoundId);
      }

    });
  },

  backSound({ state, commit, getters }) {
    let index = getters.currentSoundIndex;
    const size = _.size(getters.historySounds);
    const isExistBackSound = !!getters.historySounds[(--index + size) % size];
    if (!isExistBackSound) return;
    commit('decrementSoundIndex');
    commit('setStartGameTime');
  },

  changeDifficultyLevel({ commit }, value) {
    commit('setCurrentDifficulty', value);
  },

  prepareToNextGame({ state, getters, commit, dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        commit('setLoading', true);
        const soundId = getters.soundOfHistory['id'];
        commit('removeOfHistory', getters.currentSoundIndex);
        let notGuessedSounds = _.omit(getters.notGuessedSounds, soundId);
        if (!_.size(notGuessedSounds)) {
          await dispatch('getGameSounds');
          await new Promise(r => setTimeout(r, 500));
          notGuessedSounds = state.sounds;
        };
        const levelNotGuessed = _.pickBy(notGuessedSounds, obj => obj.difficulty.name == parseInt(state.currentDifficulty));
        const newSoundId = _.sample(levelNotGuessed)._id;
        commit('addToHistory', newSoundId);
        commit('setStartGameTime');
        commit('addGuessedSound', soundId);
        resolve(newSoundId);
      } catch (err) {
        console.error(err);
      } finally {
        commit('setLoading', false);
      };
    });
  },
};


store.mutations = {
  clearGameModule(state) {
    for (const key in state)
      Vue.delete(state, key);
    for (const key in store.state())
      Vue.set(state, key, store.state()[key]);
  },
  setCurrentSoundId: (state, soundId) => state.currentSoundId = soundId,
  setSounds: (state, sounds) => Vue.set(state, 'sounds', { ...state.sounds, ...sounds }),
  setGameSettings: (state, settings) => Vue.set(state, 'settings', settings),
  setGameScores: (state, scores) => Vue.set(state, 'scoresValues', scores),
  setGameLibrary: (state, library) => Vue.set(state, 'library', library),
  setGameSpeeds: (state, speeds) => Vue.set(state, 'speeds', speeds),
  setLoading: (state, loading) => Vue.set(state, 'loading', loading),
  setToken: (state, token) => Vue.set(state, 'token', token),
  addToHistory(state, soundId) {
    const size = _.size(state.historySounds[state.currentDifficulty]);
    const historySounds = _.get(state.historySounds, state.currentDifficulty, {});

    Vue.set(historySounds, size, {
      showName: false,
      id: soundId,
    });

    Vue.set(state.historySounds, state.currentDifficulty, historySounds);
  },
  removeOfHistory(state, soundIndex) {
    const historySounds = _.reduce(state.historySounds[state.currentDifficulty], (acc, sound, index) => {
      if (parseInt(index) === soundIndex) return acc;
      const newIndex = index > soundIndex ? index - 1 : index;
      return { ...acc, [newIndex]: sound };
    }, {});
    Vue.set(state.historySounds, state.currentDifficulty, historySounds);
  },
  addGuessedSound(state, soundId) {
    const historyGuessedSounds = [...state.historyGuessedSounds, soundId];
    Vue.set(state, 'historyGuessedSounds', historyGuessedSounds);
  },
  incrementSoundIndex(state) {
    const difficulty = state.currentDifficulty;
    const maxIndex =_.size(_.pickBy(state.sounds, sound => sound.difficulty.name == difficulty)) - 1;
    let currentIndex = _.get(state.currentSoundIndex, difficulty, 0);
    const isLoadAllSounds = _.get(state.difficultyStatuses[difficulty], 'loadAllSounds');
    const newIndex = isLoadAllSounds ? ++currentIndex % maxIndex : ++currentIndex;
    Vue.set(state.currentSoundIndex, difficulty, newIndex);
  },
  decrementSoundIndex(state) {
    const difficulty = state.currentDifficulty;
    const maxIndex =_.size(_.pickBy(state.sounds, sound => sound.difficulty.name == difficulty)) - 1;
    let currentIndex = _.get(state.currentSoundIndex, difficulty, 0);
    Vue.set(state.currentSoundIndex, difficulty, (--currentIndex + maxIndex) % maxIndex);
  },
  addWordTipsToSound(state, { sound, words }) {
    Vue.set(sound.fragment, 'tips', words);
  },
  addNameTipToSound(state, { sound, name }) {
    Vue.set(sound, 'name', name);
  },
  setShowSoundNameByIndex(state, { index, value }) {
    const historySounds = _.get(state.historySounds, state.currentDifficulty, {});
    Vue.set(state.historySounds, state.currentDifficulty, historySounds);
    const historySound = _.get(historySounds, index, {});
    Vue.set(state.historySounds[state.currentDifficulty], index, historySound);
    Vue.set(state.historySounds[state.currentDifficulty][index], 'showName', value);
  },
  setCurrentDifficulty(state, value) {
    Vue.set(state, 'currentDifficulty', value);
  },

  setCurrentSoundWords(state, words) {
    const currentWords = _.get(state.currentSoundWords, state.currentSoundId, {});
    Vue.set(state.currentSoundWords, state.currentSoundId, currentWords);
    for (const position in words) {
      if (!currentWords[position]) {
        Vue.set(state.currentSoundWords[state.currentSoundId], position, words[position]);
      } else {
        for (const key in words[position])
          Vue.set(currentWords[position], key, words[position][key]);
      };
    };
  },
  setCurrentSoundAnswerWords(state, words) {
    const currentWords = _.get(state.currentSoundAnswerWords, state.currentSoundId, {});
    Vue.set(state.currentSoundAnswerWords, state.currentSoundId, currentWords);
    for (const position in words) {
      if (!currentWords[position]) {
        Vue.set(state.currentSoundAnswerWords[state.currentSoundId], position, words[position]);
      } else {
        for (const key in words[position])
          Vue.set(currentWords[position], key, words[position][key]);
      };
    };
  },
  deleteCurrentSoundAnswerWord(state, position) {
    Vue.delete(state.currentSoundAnswerWords[state.currentSoundId], position);
  },

  updateCompletedSounds(state, types) {
    for (const type in types)
      Vue.set(state.completedSounds, type, types[type]);
  },

  setStartGameTime: state => Vue.set(state, 'startGameTime', new Date()),
  incrementAdCount: state => Vue.set(state, 'adCount', state.adCount + 1),
  clearAdCount: state => Vue.set(state, 'adCount', 0),
  clearSounds: state => state.sounds = {},
  loadAllSoundsByDifficulty(state, difficulty) {
    const settings = _.get(state.difficultyStatuses, difficulty, {});
    settings.loadAllSounds = true;
    Vue.set(state.difficultyStatuses, difficulty, settings);
  },
};

export default store;